<template>
  <div class="col-sm-6 col-md-4 p-2">
    <div class="row mx-0 p-3 card_item text-center">
      <div class="col-12 green-text font12 bold">
        FUNDRAISER ID: {{ fundraiser.fundraiserID }}
      </div>
      <div class="col-12 green-text font20">
        {{ fundraiser.name }}
      </div>
      <div class="col-12 green-text" v-if="fundraiser.mobile">
        Mobile: {{ fundraiser.mobile }}
      </div>
      <div class="col-12 green-text" v-if="fundraiser.email">
        Email: {{ fundraiser.email }}
      </div>
      <div class="col-12 gold-text font12 bold">
        <div class="row m-0 justify-content-center align-items-center">
          <div class="col-auto p-0">
            <IconLocation size="size12" />
          </div>
          <div class="col-auto ps-1 pe-0">
            {{ fundraiser.region }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineAsyncComponent } from 'vue'

export default {
  name: 'FundraiserCard',
  props: ['fundraiser'],
  components: {
    IconLocation: defineAsyncComponent(() => import('@/components/icons/IconLocation.vue'))
  }
}
</script>

<style scoped>
.card_item {
  background-color: #fff;
  border-radius: 13px;
  box-shadow: 3px 3px 25px rgba(0, 0, 0, 0.08);
}
</style>
